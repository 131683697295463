import styled, { css, keyframes } from "styled-components";

import AgeSrc from "../../images/age-confirm.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Root = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 41, 41, 0.7);
  animation-duration: 75ms;
  animation-timing-function: ease-out;
  outline: 0;
  z-index: 7000;
  backdrop-filter: blur(3px);

  @media (min-width: 1024px) and (max-height: 534px){
    padding: 48px 0;
    overflow-y: auto;
  }

  @media (min-width: 1024px) and (min-height: 535px) {
    align-items: center;
  }

  ${(p) =>
    p.$isShow &&
    css`
      animation-name: ${fadeIn};
    `}

  ${(p) =>
    p.$isHide &&
    css`
      animation-name: ${fadeOut};
    `}
`;

export const Dialog = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: 648px;
    height: 487px;
    margin: 0 auto;
    overflow: hidden;
    pointer-events: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #141f35;
  outline: 0;
  pointer-events: all;

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    background-clip: padding-box;
    background-image: url(${AgeSrc});
    background-repeat: no-repeat;
    background-size: 621px 461px;
    background-position: center;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 48px 0;
  overflow-y: auto;

  @media (min-width: 1024px) {
    justify-content: center;
  }

  @media (min-height: 535px) {
    justify-content: center;
  }
`;

export const Logo = styled.img`
  @media (max-width: 1023px) {
    width: 85%;
    max-width: 460px;
  }

  @media (min-width: 1024px) {
    height: 35px;
  }
`;

export const Text = styled.p`
  padding: 32px 16px;
  color: #63656a;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

export const Label = styled.span`
  margin-bottom: 24px;
  color: #fff;
  font-family: "DharmaGothic";
  font-size: 24px;
  letter-spacing: 0.1em;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 180px;
  }
`;

export const Select = styled.select`
  height: 36px;
  padding: 0 4px;
  color: #d79d5c;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #ce9b61;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    max-width: 350px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1024px) {
    width: 124px;
    margin: 0 4px;
  }
`;

export const Submit = styled.button`
  width: 132px;
  height: 48px;
  margin-top: 45px;
  color: #fff;
  font-family: "DharmaGothic";
  font-size: 30px;
  letter-spacing: 0.05em;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #fff;
`;
