import { createGlobalStyle } from 'styled-components';

import DharmaGothicRegular from '../fonts/DharmaGothicE_Regular_R.otf';
import DharmaGothicBold from '../fonts/DharmaGothicE_Bold_R.otf';
import GreatWestern from '../fonts/GreatWestern.otf';
import GrocersScript from '../fonts/Grocers-Script-Regular.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: "DharmaGothic";
    font-style: normal;
    font-weight: 400;
    src: local(''), url(${DharmaGothicRegular}) format('opentype');
  }

  @font-face {
    font-family: "DharmaGothic";
    font-style: normal;
    font-weight: 700;
    src: local(''), url(${DharmaGothicBold}) format('opentype');
  }

  @font-face {
    font-family: "GreatWestern";
    font-style: normal;
    font-weight: 400;
    src: local(''), url(${GreatWestern}) format('opentype');
  }

  @font-face {
    font-family: "GrocersScript";
    font-style: normal;
    font-weight: 400;
    src: local(''), url(${GrocersScript}) format('truetype');
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    box-sizing: border-box;
    font-size: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    min-width: 375px;
    margin: 0;
  }

  *,
  *:focus {
    outline: none;
  }

  *::-moz-focus-inner {
    border: 0 none;
  }

  a {
    display: inline-block;
    max-width: 100%;
    color: inherit;
    text-decoration: none;
  }

  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    font-weight: 200;
    letter-spacing: 0.02em;
  }

  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 500;
  }

  h1 {
    font-size: 40px;
    line-height: 1;
    margin-bottom: 8px;
  }

  h2 {
    font-size: 35px;
    line-height: 1.3333333333333333;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 21px;
    line-height: 1;
    margin-bottom: 8px;
  }

  h4 {
    font-size: 18px;
    line-height: 1.1428571428571428;
    margin-bottom: 8px;
  }

  h5 {
    font-size: 16px;
    line-height: 1.3333333333333333;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
  }


  p,
  blockquote,
  dl,
  ol,
  ul,
  dd,
  dt,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }

  button:hover {
    cursor: pointer;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  textarea {
    resize: vertical;
  }

  small {
    font-size: 88%;
  }

  #___gatsby {
    height: 100%;
    min-height: 100%;
  }

  ::-webkit-scrollbar-track {
    background-color: #ccc;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9ea2aa;
    transition: all .3s ease-in-out;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  .grecaptcha-badge {
    visibility: hidden;
    z-index: 1000;
  }

           /*style the box which holds the text of the information window*/  
           .gm-style .gm-style-iw {
            background-color: #ffffff !important;
            top: 0 !important;
            left: 0 !important;
            width: 240px;
            ${'' /* height: 100% !important; */}
            min-height: 120px !important;
            padding-top: 10px;
            display: block !important;
         }    

         /*style the paragraph tag*/
         .gm-style .gm-style-iw #google-popup p{
            padding: 10px;
         }


        /*style the annoying little arrow at the bottom*/
        .gm-style div div div div div div div div {
            background-color: #ffffff !important;
            margin: 0;
            padding: 0;
            top: 0;
            color: #fff;
            font-size: 16px;
        }

        /*style the link*/
        ${'' /* .gm-style div div div div div div div div a {
            color: #f1f1f1;
            font-weight: bold;
        } */}

        .gm-style-iw.gm-style-iw-c {
          border-radius: 0;
          box-shadow: none;
        }

        .gm-ui-hover-effect {
          display: none !important;
        }
        
`;
